<template>
	<div class="full-height flex-column">

		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('goBack')"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<div>
							<h2 class="page_tit"> {{ program.title }} </h2>
							<span class="page_subtit">{{  item_cartel.cartl_name }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="mt-50 pa-10-20"
		>
			<DatePicker v-if="subscrp_year" class="mt-30" :type="'year'" :date="subscrp_year" @click="setDate($event)" ></DatePicker>

			<div class="mt-30 justify-space-between items-center">
				<div class="img-box-100 radius-20 mr-10">
					<img :src="item_plane.subscrp_plan_img_url" @error="$bus.$emit('onErrorImage', $event)" class="object-cover" />
				</div>
				<div class="flex-1">
					<div class="mt-10 font-weight-700 size-px-16">{{ item_plane.subscrp_plan_name }}</div>
					<div class="mt-10 size-px-16 font-weight-600 color-blue"><img :src="require('@/assets/image/icon_won_blue.svg')" /> {{ item_plane.subscrp_amount | makeComma }}원</div>
				</div>
			</div>
			<div
				class="mt-30"
			>
				<ul
					class="size-px-14"
				>
					<li
						v-for="(history, h_index) in items_list"
						:key="'history_' + h_index"
						class="under-line-not-last pb-20 mb-20"
						@click="onDetail(history)"
					>
						<div
							v-if="false"
							class="justify-space-between items-center mt-10"
						>
							<div>닉네임</div>
							<div>{{ history.nickname }}</div>
						</div>
						<div
							v-if="false"
							class="justify-space-between items-center mt-10"
						>
							<div>구독상품</div>
							<div class="font-weight-600">{{ history.subscrp_plan_name }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point_result.title_payment_type }} <!-- 결제 수단--></div>
							<div class="font-weight-600">{{ history.payment_mthd_name }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point_result.title_payment_amount }} <!-- 결제 금액 --></div>
							<div class="font-weight-600">{{ history.amount | makeComma }}원</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point_result.title_payment_date }} <!-- 거래일시 --></div>
							<div class="font-weight-600">{{ history.pmtdate  | transDateTime }}</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<PopupLayer
			v-if="is_on_detail"
		>
			<template
				v-slot:body
			>
				<div
					class=" bg-white flex-column justify-space-between radius-20"
				>
					<div class="size-px-14 pa-20">
						<div class="size-px-20 text-center font-weight-bold under-line pb-20">{{ $language.point_result.title_payment_detail }} <!-- 결제 상세내역 --></div>

						<div class="mt-30">
							<h4>{{ $language.point_result.title_information }} <!-- 정보 --></h4>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.common.nickName }} <!-- 닉네임 --></div>
								<div>{{ item.nickname }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.point_result.title_subscribe_product }} <!-- 구독상품 --></div>
								<div>{{ item.subscrp_plan_name }}</div>
							</div>
						</div>
						<div class="mt-30">
							<h4>{{ $language.point_result.title_payment_information }} <!-- 결제정보 --></h4>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.point_result.title_tid }} <!-- 거래번호 --></div>
								<div>{{ item.nickname }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.point_result.title_payment_number }} <!-- 승인번호 --></div>
								<div class="font-weight-700">{{ item.subscrp_plan_name }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.point_result.title_payment_type }} <!-- 결제 수단--></div>
								<div class="font-weight-700">{{ item.subscrp_plan_name }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.point_result.title_payment_amount }} <!-- 결제 금액 --></div>
								<div>{{ item.amount | makeComma }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.point_result.title_payment_date }} <!-- 거래일시 --></div>
								<div>{{ item.pmtdate }}</div>
							</div>
						</div>
					</div>

					<div class="mt-auto">
						<button
							@click="offDetail"
							class="width-100 bg-blue-mafia pa-10 color-white size-px-16 "
						>{{ $language.point_result.btn_confirm }}<!-- 확인 --></button>
					</div>
				</div>
			</template>
		</PopupLayer>
	</div>
</template>

<script>

import PopupLayer from "@/view/Layout/PopupLayer";
import DatePicker from "@/components/DatePicker";

export default {
	name: 'CartelSubscribeHistory'
	, components: {DatePicker, PopupLayer}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'payment list'
				, title: '구독내역'
				, not_footer: true
				, type: 'mypage'
			}
			, item_cartel: {}
			, item_plane: {
				payment_subscrp_plan_number: '123'
				, payment_subscrp_plan_name: '구독 플랜명'
				, payment_subscrp_plan_price: '5000'
				, payment_subscrp_enddate: '2023.07.10 구독 종료 예정'
				, payment_subscrp_plan_img_url: ''
			}
			, items: []
			, is_on_detail: false
			, subscrp_year: this.$date.init().year
		}
	}
	, computed: {
		items_list: function(){
			return this.items.filter((item) => {
				if(item.payment_coin_quantity > 0){
					item.amount = item.payment_coin_quantity
				}else{
					item.amount = item.payment_amount
				}
				return item
			})
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_subscribe_history
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, subscrp_plan_number: this.$route.params.p_id
						, subscrp_year: this.subscrp_year
					}
					, type: true
				})

				if(result.success){
					this.item_plane = result.data.subscrp_plan_info
					this.items = result.data.subscrp_plan_subscrp_particulars_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setDate: function(date){
			this.subscrp_year = date
			this.getData()
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getCartel()
		this.getData()

	}
}
</script>
